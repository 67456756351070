<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="row col-3 mb-2">
          <AddTMS @reload="updatePage(1)"></AddTMS>
        </div>
      </div>
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                :items="tms_data"
                :fields="fields"
                responsive="sm"
                :busy="isBusy"
                :per-page="perPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                tbody-tr-class="rowClass"
                :filter-included-fields="filterOn"
              >
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                </template>

                <template #cell(createdAt)="row">
                  {{ moment(row.item.createdAt).format("lll") }}
                  <br />
                  <b-badge variant="danger" @click="deleteTms(row.item)"
                    >Delete</b-badge
                  >
                </template>
                <template #cell(user)="row">
                  <div
                    @click="inspectionSelected(row.item)"
                    v-if="row.item.user"
                  >
                    +{{ row.item.user && row.item.user.country_code }} -
                    {{ row.item.user && row.item.user.phone_number }}
                  </div>
                </template>
                <template #cell(serial_number)="row">
                  <AddTMS :tms="row.item"></AddTMS>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                      @change="updatePage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import AddTMS from "@/views/pages/terminals/add-terminals";
import { terminals } from "@/config/api/terminals";
import Swal from "sweetalert2";

export default {
  components: { Layout, AddTMS },
  data() {
    return {
      tms_data: [],
      isBusy: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 150,
      pageOptions: [150, 250, 500, 100],
      filter: null,
      filterOn: [],
      sortBy: "createdAt",
      sortDesc: true,
      fields: [
        { key: "serial_number", sortable: false, label: "Serial Number" },
        { key: "user", sortable: false },
        { key: "createdAt", sortable: false },
        { key: "updatedAt", sortable: false },
      ],
    };
  },
  computed: {},
  mounted() {},
  created() {
    this.getAllTMS();
  },
  watch: {
    filter: function (newVal) {
      if (newVal.length > 2 || newVal.length === 0) {
        setTimeout(() => {
          this.getAllTMS(1, this.filter);
        }, 2000);
      }
    },
  },
  methods: {
    deleteTms(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          this.deleteAction(id);
        }
      });
    },
    deleteAction(tmsValue) {
      console.log(tmsValue);
      let api = terminals.TmsDelete;
      api.id = tmsValue._id;
      this.generateAPI(api)
        .then(() => {
          this.getAllTMS(1);
          this.$bvToast.toast("TMS record deleted successfully!", {
            title: "Deleted record",
            variant: "success",
            autoHideDelay: 2000,
          });

          Swal.fire("Deleted!", "Campaign has been deleted.", "success");
        })
        .catch((err) => {
          Swal.fire(
            "Error!",
            `Campaign is not deleted,${err.response.data.error}`,
            "error"
          );
        });
    },

    finishCampaign(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, finish it!",
      }).then((result) => {
        if (result.value) {
          this.finishAction(id);
        }
      });
    },
    finishAction(id) {
      const api = terminals.finishCampaign;
      api.data = { campaign_id: id };
      this.generateAPI(api)
        .then(() => {
          if (this.campaignData.length === 1) {
            const page =
              this.currentPage == 1
                ? this.currentPage
                : parseInt(this.currentPage) - 1;

            this.$store.dispatch("campaign/changePage", page);
            this.getAllCampigns(page);
            this.currentPage = page;
          } else {
            this.getAllCampigns(this.currentPage);
          }

          Swal.fire("Finished!", "Campaign has been finished.", "success");
        })
        .catch((err) => {
          Swal.fire(
            "Error!",
            `Campaign is not finished,${err.response.data.error}`,
            "error"
          );
        });
    },
    watch: {
      reload(val) {
        if (val) {
          this.reloadData();
        }
      },
    },

    // deleteTMS(tmsValue){
    //   console.log(tmsValue)
    //   let api= terminals.TmsDelete;
    //   api.id=tmsValue._id;
    //   this.generateAPI(api).then(( )=>{
    //     this.getAllTMS(1);
    //     this.$bvToast.toast("TMS record deleted successfully!", {
    //         title: "Deleted record",
    //         variant: "success",
    //          autoHideDelay: 2000,
    //       });
    //   })

    // },

    // deleteCampaign(tmsValue) {
    //   Swal.fire({
    //     title: "Are you sure?",
    //     text: "You won't be able to revert this!",
    //     icon: "warning",
    //     showCancelButton: true,
    //     confirmButtonColor: "#34c38f",
    //     cancelButtonColor: "#f46a6a",
    //     confirmButtonText: "Yes, delete it!",
    //   }).then((result) => {
    //     if (result.value) {
    //       this.deleteTMS(tmsValue);
    //     }
    //   });
    // },

    updatePage(page) {
      this.getAllTMS(page);
    },
    getAllTMS(page = 1, search = "") {
      this.isBusy = true;
      const api = terminals.getAll;
      api.params = { page: page, search: search, limit: this.perPage };
      this.generateAPI(api)
        .then((res) => {
          this.tms_data = res.data.tms;
          this.totalRows = res.data.total_no_of_documents;
        })
        .catch((err) => {
          console.error(err.response.data);
        })
        .finally(() => {
          this.isBusy = false;
        });
    },

    inspectionSelected(data) {
      this.$router.push({
        name: "TrackDriver",
        query: { driver_id: data.user._id },
      });
    },
  },
};
</script>

<style>
.rowClass {
  cursor: pointer;
}

.rowClass:hover {
  background: #f2f2f2;
  /* color: #ffffff; */
}
</style>
