export const terminals = {
  get: {
    url: "tms/getAllInspection",
    method: "GET",
  },
  getAll: {
    url: "tms/get/",
    method: "GET",
    id: null,
    params: null,
  },
  search: {
    url: "tms/search",
    method: "GET",
    id: null,
    params: null,
  },
  create: {
    url: "tms/create/",
    method: "POST",
    id: null,
    data: null,
  },
  update: {
    url: "tms/update/",
    method: "PUT",
    id: null,
    data: null,
  },
  lastLocation: {
    url: "driver/last_location/",
    method: "GET",
    id: null,
    data: null,
  },
  last_location_by_campaign: {
    url: "/vehicle/last_location_by_campaign/",
    method: "GET",
    id: null,
    data: null,
  },
  TmsDelete: {
    url: "/tms/delete/",
    method: "DELETE",
    id: null,
    data: null,
  },
};
